import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import { TextField, Button, Box, Typography, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Switch } from '@mui/material';
import pubsub from '../utils/pubsub'; // Use the existing pubsub

const TTSComponent = () => {
  const [text, setText] = useState('');
  const [audioSrc, setAudioSrc] = useState(null);
  const [isTTSEnabled, setIsTTSEnabled] = useState(false); // State to track TTS toggle

  // Get allowTTS from URL and add debug logs
  const searchParams = new URLSearchParams(window.location.search);
  const allowTTS = searchParams.get('allowTTS') === 'true';
  
  // console.log('URL search params:', window.location.search);
  // console.log('allowTTS param:', searchParams.get('allowTTS'));
  // console.log('allowTTS value:', allowTTS);

  useEffect(() => {
    console.log(`TTS is ${isTTSEnabled ? 'enabled' : 'disabled'}`);
  }, [isTTSEnabled]);

  useEffect(() => {
    // When LLM has a message it will publish that message, we Subscribe to that event here and convert to speech if TTS is enabled
    const unsubscribe = pubsub.subscribe('newBotMessage', (botMessage) => {
      if (isTTSEnabled) {
        handleTTS(botMessage);
      }
    });

    return () => {
      unsubscribe(); // Clean up the subscription on unmount
    };
  }, [isTTSEnabled]);

  const handleTTS = async (textToConvert) => {
    try {
      const response = await axios.post(config.ttsUrl, {
        text: textToConvert,
      });

      const audioData = response.data.audioData;

      // When the audio is ready, Publish the audio data URL for SoftPhoneLogic to play
      pubsub.publish('receivedTTSAudio', audioData);

    } catch (error) {
      console.error('Error converting text to speech:', error);
    }
  };

  const handleTTSToggle = (event) => {
    setIsTTSEnabled(event.target.checked);
  };

  return (
    <Box sx={{ maxWidth: 540, mx: 'auto', mt: 4, p: 1, border: '0px solid #ccc', borderRadius: 2, display: allowTTS ? 'block' : 'none'  }}>
      <FormControlLabel
        control={
          <Switch
            checked={isTTSEnabled}
            onChange={handleTTSToggle}
            name="tts-toggle"
            color="primary"
          />
        }
        label={isTTSEnabled ? "TTS On" : "TTS Off"}
      />
      <form onSubmit={(e) => { e.preventDefault(); if (isTTSEnabled) handleTTS(text); }}>
        <TextField
          label="TTS Text"
          multiline
          rows={5}
          fullWidth
          value={text}
          onChange={(e) => setText(e.target.value)}
          variant="outlined"
          margin="normal"
          sx={{ display: 'none'}}
        />
        <Button 
          variant="contained" 
          color="primary" 
          type="submit" 
          fullWidth 
          sx={{ display: 'none' }}
        >
          Convert to Speech
        </Button>
      </form>
      {audioSrc && (
        <Box mt={2} sx={{ display: 'none' }}>
          <Typography variant="h6">Audio Output</Typography>
          <audio controls autoPlay>
            <source src={audioSrc} type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
        </Box>
      )}
    </Box>
  );
};

export default TTSComponent;
