import React from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Container,
  Stack
} from '@mui/material';
import { SoftPhoneLogic } from './SoftPhoneLogic';

/**
 * SoftPhone component for handling VoIP calls using SIP.js
 * 
 * This component provides a user interface for making and receiving VoIP calls.
 * It includes functionality for:
 * - Registering with a SIP server
 * - Making outbound calls
 * - Receiving inbound calls
 * - Handling call states (ringing, connected, terminated)
 * - Playing a ringtone for incoming calls
 * 
 * @component
 * @example
 * return (
 *   <SoftPhone />
 * )
 */
const SoftPhone = () => {
  const {
    userExtension,
    status,
    dialNumber,
    setDialNumber,
    showAnswerReject,
    isCallActive,
    isCallInitiated,
    isCallOnHold,
    remoteAudioRef,
    makeCall,
    hangUp,
    answerCall,
    rejectCall,
    toggleHold,
    forwardCall // Add this line to destructure forwardCall from SoftPhoneLogic
  } = SoftPhoneLogic();

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
        <Box>
          <Typography variant="h5" gutterBottom>
            Tradesly AI
          </Typography>
          <Typography 
             
            gutterBottom 
            sx={{ color: 'primary.main' }} // This will use the primary color of your theme, which is typically blue
          >
            Agent's Extension: {userExtension}
          </Typography>
          <TextField
            fullWidth
            value={dialNumber}
            onChange={(e) => setDialNumber(e.target.value)}
            placeholder="Enter number"
            margin="normal"
            variant="outlined"
            InputProps={{
              style: {
                fontSize: '1.2rem',
                color: 'gray',
              }
            }}
            sx={{
              mb: 2,
            }}
          />
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            {!isCallActive && !showAnswerReject && !isCallInitiated && (
              <Button
                variant="contained"
                color="success"
                fullWidth
                onClick={makeCall}
              >
                Call
              </Button>
            )}
            {isCallActive && (
              <Button
                variant="contained"
                color={isCallOnHold ? "warning" : "primary"}
                fullWidth
                onClick={toggleHold}
              >
                {isCallOnHold ? "Resume" : "Hold"}
              </Button>
            )}
            {isCallActive && (
              <Button
                variant="contained"
                color="info"
                fullWidth
                onClick={forwardCall} // Add this button for forwarding calls
              >
                Forward
              </Button>
            )}
            {!showAnswerReject && (
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={hangUp}
              >
                Hang Up
              </Button>
            )}
          </Stack>
          {showAnswerReject && (
            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
              <Button
                variant="contained"
                color="success"
                fullWidth
                onClick={answerCall}
              >
                Answer
              </Button>
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={rejectCall}
              >
                Reject
              </Button>
            </Stack>
          )}
          <Typography variant="body1" sx={{ mt: 2 }}>
            {status}
          </Typography>
          <audio ref={remoteAudioRef} autoPlay style={{ display: 'none' }} />
        </Box>
      </Paper>
    </Container>
  );
};

export default SoftPhone;
