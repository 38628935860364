// In your React component
import React, { useEffect, useState, useRef } from 'react';
import { Container, Typography, List, ListItem, ListItemText, Paper, Alert, Switch, FormControlLabel } from '@mui/material';
import pubsub from '../utils/pubsub';
import config from '../config';

const TranscriptDisplay = () => {
    const [transcripts, setTranscripts] = useState([]);
    const [error, setError] = useState(null); // State to store error messages
    const listRef = useRef(null); // Create a ref for the list
    // const lastPublishTimeRef = useRef(0); // Add this line to keep track of the last publish time
    // const pendingMessageRef = useRef('');
    // const publishTimeoutRef = useRef(null); // Add this to store the timeout
    const [isEnabled, setIsEnabled] = useState(true); // Add state for toggle

    // Function to publish pending message
    // const publishPendingMessage = () => {
    //     if (pendingMessageRef.current) {
    //         console.log(' >>>>>> Publishing delayed message:', pendingMessageRef.current);
    //         pubsub.publish('newTranscript', pendingMessageRef.current.trim());
    //         pendingMessageRef.current = '';
    //         lastPublishTimeRef.current = Date.now();
    //     }
    // };

    // Add clearTranscripts method
    const clearTranscripts = () => {
        setTranscripts([]);
        // pendingMessageRef.current = '';
        // if (publishTimeoutRef.current) {
        //     clearTimeout(publishTimeoutRef.current);
        // }
        console.log(' >>>>> Transcripts cleared');
    };

    // Subscribe to clear event
    useEffect(() => {
        const unsubscribe = pubsub.subscribe('clearTranscripts', () => {
            clearTranscripts();
        });

        return () => {
            unsubscribe();
        };
    }, []);

    // Add toggle handler
    const handleToggle = () => {
        setIsEnabled(prev => !prev);
        if (isEnabled) {
            clearTranscripts();
            pubsub.publish('transcriptDisabled', true);
        } else {
            pubsub.publish('transcriptEnabled', true);
        }
    };

    useEffect(() => {
        setError(null);
        if (!isEnabled) return; // Exit early if disabled

        try {
            const ws = new WebSocket(config.transcriptWsUrl);
            // let numberOfPeopleSpeaking = 1;
            // let agent = 1;
            let speaker = 1;

            ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                // console.log(`=== event.data string: ${event.data}`);
                
                if (data.transcript.text) {
                    speaker = data.transcript.speaker + 1;
                    setTranscripts((prev) => [...prev, `[P${speaker}] ${data.transcript.text}`]);

                    pubsub.publish('newTranscript', data.transcript.text);

                    // if (speaker > numberOfPeopleSpeaking) {
                    //     numberOfPeopleSpeaking = 2;
                    // }

                    // if (speaker !== agent || numberOfPeopleSpeaking === 1) {
                    //     const currentTime = Date.now();
                    //     const minPublishInterval = 1500;

                    //     // Clear any existing timeout
                    //     if (publishTimeoutRef.current) {
                    //         clearTimeout(publishTimeoutRef.current);
                    //     }

                    //     if (currentTime - lastPublishTimeRef.current >= minPublishInterval) {
                    //         const messageToPublish = pendingMessageRef.current + data.transcript.text;
                    //         console.log(' >>>>>> Publishing immediately:', messageToPublish);
                    //         pubsub.publish('newTranscript', messageToPublish);
                    //         lastPublishTimeRef.current = currentTime;
                    //         pendingMessageRef.current = '';
                    //     } else {
                    //         pendingMessageRef.current += data.transcript.text + ' ';
                    //         console.log(' >>>>>> Delay publishing, concatenated message:', pendingMessageRef.current);
                            
                    //         // Set new timeout
                    //         publishTimeoutRef.current = setTimeout(() => {
                    //             publishPendingMessage();
                    //         }, minPublishInterval);
                    //     }
                    // }
                }
            };

            // Cleanup function
            return () => {
                ws.close();
                // const timeoutId = publishTimeoutRef.current;
                // if (timeoutId) {
                //     clearTimeout(timeoutId);
                // }
                // // Publish any remaining pending message
                // if (pendingMessageRef.current) {
                //     publishPendingMessage();
                // }
            };

        } catch (error) {
            console.error('WebSocket error:', error);
        }
    }, [isEnabled]); // Add isEnabled to dependency array

    // Auto-scroll to the bottom when transcripts change
    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight;
        }
    }, [transcripts]);

    return (
        <Container sx={{ mt: 3 }}>
            <Paper elevation={3} sx={{ p: 1 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" gutterBottom align="left">
                        Caller's transcripts
                    </Typography>
                    <FormControlLabel style={{ display: 'none' }}
                        control={<Switch checked={isEnabled} onChange={handleToggle} />}
                        label={isEnabled ? "Enabled" : "Disabled"}
                    />
                </div>
                
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                    <List ref={listRef} sx={{ height: 200, overflowY: 'auto' }}>
                        {transcripts.map((transcript, index) => (
                            <ListItem 
                                key={index} 
                                sx={{ 
                                    py: 0.5, // Reduce vertical padding
                                    '&:first-of-type': { pt: 1 }, // Add a bit more padding to the first item
                                    '&:last-of-type': { pb: 1 }   // Add a bit more padding to the last item
                                }}
                            >
                                <ListItemText
                                    primary={transcript}
                                    primaryTypographyProps={{ 
                                        sx: { 
                                            lineHeight: 1.2,
                                            my: 0 // Remove vertical margin
                                        } 
                                    }}
                                />
                            </ListItem>
                        ))}
                    </List>
            </Paper>
        </Container>
    );
};

export default TranscriptDisplay;
