import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Container, TextField, Button, Typography, Paper, CircularProgress, Switch, FormControlLabel } from '@mui/material';
import axios from 'axios';
import config from '../config';
import pubsub from '../utils/pubsub'; // Use the existing pubsub
import ReactMarkdown from 'react-markdown';
import removeMarkdown from 'remove-markdown';

const ChatGPTComponent = () => {
    const [question, setQuestion] = useState('');
    const [conversation, setConversation] = useState([]); // Store the conversation history
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const conversationEndRef = useRef(null); // Ref to track the end of the conversation
    const [enableAssistant, setEnableAssistant] = useState(false); // Default is off
    // Add new state for accumulated transcripts
    const [accumulatedTranscript, setAccumulatedTranscript] = useState('');

    // Move systemMessage into useMemo
    const systemMessage = useMemo(() => ({
        role: 'system',
        content: `You are a customer service representative at AquaFix Solutions, an plumbing service company. When a client reaches out with an inquiry, follow these steps:

        1. Greet the customer warmly and ask for their name. For example, “Thank you for calling AquaFix Solutions! My name is Alex. How can I help you today?”

        2. Identify the customer's needs:

        - If the customer is looking for plumbing services, proceed by asking for the following information:
            Address: "May I have your name and the address where you need the service?"
            Ask about their need for plumbing services.
            Ask about the availability of the service: "What day and time would be best for you?"
            Inform them: "Thank you for providing the information! One of our team members will get back to you within 30 minutes to discuss scheduling availability."
            Confirm them: "Could I confirm your contact number and email address so we can send you the appointment confirmation?"

        - If the inquiry is not related to plumbing services, try to be as helpful as possible.
        
        Ensure the conversation remains professional, helpful, and polite throughout. Ask only one question at a time. Respond should be short and to the point.`
    }), []); // Empty dependency array since content is static

    // Method to send a message to the server
    const getResponseFromLLM = useCallback(async (userMessage) => {
        setLoading(true);
        setError(null);

        console.log(' ###### getResponseFromLLM for:', userMessage);
        // Add the user's message to the conversation history
        const updatedConversation = [...conversation, { role: 'user', content: userMessage }];

        try {
            const result = await axios.post(
                'https://api.openai.com/v1/chat/completions',
                {
                    model: config.chatGPTModel,
                    messages: [systemMessage, ...updatedConversation],  // Include full conversation history
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${config.chatGPTApiKey}`,
                    },
                }
            );

            const responseWithMarkdown = result.data.choices[0].message.content;
            const llmResponse = removeMarkdown(responseWithMarkdown);
            console.log(' ###### Bot response:', llmResponse);

            // Publish the response to be used by TTSComponent
            pubsub.publish('newBotMessage', llmResponse);

            setConversation([...updatedConversation, { role: 'assistant', content: responseWithMarkdown }]);

        } catch (err) {
            setError('An error occurred while fetching the response.');
            console.error('Error:', err);
        } finally {
            setLoading(false);
        }
    }, [conversation, systemMessage]); // Add systemMessage to dependencies

    // Add this function to handle the switch change
    const handleAssistantToggle = (event) => {
        setEnableAssistant(event.target.checked);
        clearChat();
    };

    // Modify the subscription effect
    useEffect(() => {
        let unsubscribe1, unsubscribe2;
        
        if (enableAssistant) {
            // Subscribe to new transcripts to accumulate them
            unsubscribe1 = pubsub.subscribe('newTranscript', (transcript) => {
                setAccumulatedTranscript(prev => {
                    const newValue = (prev ? prev + ' ' : '') + transcript.trim();
                    console.log('New accumulated value:', newValue);
                    return newValue;
                });
            });

            // Subscribe to silence detection
            unsubscribe2 = pubsub.subscribe('silenceDetected', (isSilent) => {
                console.log(' ###### Silence detected:', isSilent);
                if (isSilent) {
                    pubsub.publish('checkTranscript');
                }
            });
        }

        return () => {
            if (unsubscribe1) unsubscribe1();
            if (unsubscribe2) unsubscribe2();
        };
    }, [enableAssistant]);

    // Separate effect for handling the transcript
    useEffect(() => {
        const handleTranscriptCheck = () => {
            if (accumulatedTranscript.trim()) {
                console.log('Calling LLM with:', accumulatedTranscript.trim());
                getResponseFromLLM(accumulatedTranscript.trim());
                setAccumulatedTranscript('');
            }
        };

        const unsubscribe = pubsub.subscribe('checkTranscript', handleTranscriptCheck);
        return () => unsubscribe();
    }, [accumulatedTranscript, getResponseFromLLM]);

    useEffect(() => {
        if (conversationEndRef.current) {
            conversationEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [conversation]);


    const handleSubmit = (e) => {
        e.preventDefault();
        getResponseFromLLM(question);
        setQuestion(''); // Clear the input field
    };

    // Add clearChat method
    // const clearChat = () => {
    //     setConversation([]);
    //     setError(null);
    //     console.log(' >>>>> Chat conversation cleared');
    // };

    const clearChat = useCallback(() => {
        setConversation([]);
        setError(null);
        console.log(' >>>>> Chat conversation cleared');
    }, []);


    // Subscribe to clear event
    useEffect(() => {
        const unsubscribe = pubsub.subscribe('clearChat', clearChat);

        return () => {
            unsubscribe();
        };
    }, [clearChat]);


    return (
        <Container sx={{ mt: 3 }}>
            <Paper elevation={3} sx={{ p: 2 }}>

                {/* Add the switch here */}
                <FormControlLabel
                    control={
                        <Switch
                            checked={enableAssistant}
                            onChange={handleAssistantToggle}
                            name="enableAssistant"
                            color="primary"
                        />
                    }
                    label={enableAssistant ? "Assistant On" : "Assistant Off"}
                />

                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Ask a question"
                        variant="outlined"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        sx={{ mb: 2 }}
                        style={{ display: 'none' }}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={loading}
                        style={{ display: 'none' }}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Submit'}
                    </Button>
                </form>
                {error && (
                    <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                )}
                <div style={{ height: '315px', overflowY: 'auto' }}>
                    {conversation.map((msg, index) => (
                        <Typography
                            key={index}
                            variant="body1"
                            sx={{
                                mt: 2,
                                backgroundColor: index === conversation.length - 1 && msg.role === 'assistant' ? 'lightyellow' : 'transparent',
                                padding: index === conversation.length - 1 && msg.role === 'assistant' ? '8px' : '0',
                                borderRadius: index === conversation.length - 1 && msg.role === 'assistant' ? '4px' : '0',
                            }}
                        >
                            <strong>{msg.role === 'user' ? 'Human: ' : 'Bot: '}</strong>
                            <ReactMarkdown>{msg.content}</ReactMarkdown>
                        </Typography>
                    ))}
                    <div ref={conversationEndRef} />
                </div>
            </Paper>
        </Container>
    );
};

export default ChatGPTComponent;
