import React from 'react';
import { Helmet } from 'react-helmet';
import SoftPhone from './components/SoftPhone';
import TranscriptDisplay from './components/TranscriptDisplay';
import ChatGPTComponent from './components/ChatGPTComponent';
import TTSComponent from './components/TTSComponent';

function App() {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Helmet>
      <div maxWidth="sm" className="container">
        <SoftPhone  />
        <div style={{ display: 'flex', flexDirection: 'column', width: 600, justifyContent: 'center', margin: '0 auto' }}>
          <div style={{ flex: 1 }}>
            <TranscriptDisplay />
          </div>
          <div style={{ flex: 1 }}>
            <ChatGPTComponent />
          </div>
        </div>
        <TTSComponent />
      </div>
    </>
  );
}

export default App;
