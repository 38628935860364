const config = {
    sipServer: 'wss://voip3.hofamily.net:8089/ws',
    sipDomain: 'voip3.hofamily.net',
    authorizationPassword: 'SalesAI2024**',
    //chatGPTApiKey: 'sk-RnSTU1NHWcqPllcGlyB--Z47AMDuPB8V8TJxCa91MST3BlbkFJctjZghv_fEozweG92dzvcVAczPSSHz80AbvFgM6YcA',
    chatGPTApiKey: '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',
    chatGPTModel: 'chatgpt-4o-latest',  
    deepgramApiKey: '763170de7743808d8d649733905007b4be7ed529',
    transcriptWsUrl: 'wss://voip3.hofamily.net:9443',
    // transcriptWsUrl: 'wss://localhost:9443',
    // ttsUrl: 'https://demo.hofamily.net:3081/tts',
    ttsUrl: 'https://poc.hofamily.net:3081/tts',
  };
  
  export default config;