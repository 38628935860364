export class SilenceDetector {
    constructor(options = {}) {
        this.SILENCE_THRESHOLD = options.threshold || -50;
        this.SILENCE_DURATION = options.duration || 1000;
        this.silenceStart = null;
        this.hasPublishedSilence = false;
        this.onSilenceChange = options.onSilenceChange || (() => {});
        this.animationFrame = null;
    }

    start(remoteStream) {
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        this.source = this.audioContext.createMediaStreamSource(remoteStream);
        this.analyser = this.audioContext.createAnalyser();
        
        this.analyser.fftSize = 2048;
        this.analyser.smoothingTimeConstant = 0.8;
        
        this.source.connect(this.analyser);
        this.checkAudio();
    }

    checkAudio = () => {
        const dataArray = new Uint8Array(this.analyser.frequencyBinCount);
        this.analyser.getByteFrequencyData(dataArray);
        
        const average = dataArray.reduce((sum, value) => sum + value, 0) / dataArray.length;
        const volume = 20 * Math.log10(average / 255);

        if (volume < this.SILENCE_THRESHOLD) {
            if (!this.silenceStart) {
                this.silenceStart = Date.now();
                this.hasPublishedSilence = false;
            } else if (!this.hasPublishedSilence && 
                      Date.now() - this.silenceStart >= this.SILENCE_DURATION) {
                this.onSilenceChange(true);
                this.hasPublishedSilence = true;
            }
        } else {
            if (this.silenceStart) {
                this.onSilenceChange(false);
            }
            this.silenceStart = null;
            this.hasPublishedSilence = false;
        }

        this.animationFrame = requestAnimationFrame(this.checkAudio);
    }

    stop() {
        if (this.animationFrame) {
            cancelAnimationFrame(this.animationFrame);
        }
        if (this.source) {
            this.source.disconnect();
        }
        if (this.analyser) {
            this.analyser.disconnect();
        }
        if (this.audioContext) {
            this.audioContext.close();
        }
    }
}
